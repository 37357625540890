import { createStore } from 'vuex'


export default createStore({
  state: {
    darkmode: true,
    mobile: false,
    chatmessages: [
      {
        in: true,
        string: "Hallo 😊, ich bin Ihr automatischer Berater 🤖. Wie kann ich Ihnen helfen?",
        "n_cols": 1,
        "options": [
          {
            "answer": "Zum Zigarren-Berater",
            "value": "~ber_"
          }
        ]
      }
    ],
    config: Object(),
    current_api_link: "",
    config_api_link: 'https://api.chat-q.de:420/chatbot?chatbot_id=/chatbot?chatbot_id=',
    css_vars: Object()
  },
  mutations: {
    set_darkmode(state, isEnabled) {
      if (isEnabled) {
        document.documentElement.setAttribute('data-theme', 'dark');
      } else {
        document.documentElement.setAttribute('data-theme', 'light');
      }
      state.darkmode = isEnabled
    },
    change_darkmode(state) {
      this.commit("set_darkmode", !state.darkmode)
    },
    add_messages(state, messages) {
      for (var i = 0; i < messages[0].length; i++) {
        setTimeout(function (messages, i) {
          console.table(messages[i])
          messages[i].in = true;
          messages[i].has_image = "image" in messages[i];
          state.chatmessages.push(messages[i]);
          console.log("Käsesandwich")
          setTimeout(function () {
            const element = document.getElementById("messagge-window");
            if (element != null) {
              element.scrollTop = element.scrollHeight;
            }
          }, 100);
        }, i * 1000, messages[0], i);

      }

      console.log(state.chatmessages)
    },

    add_message(state, message) {
      state.chatmessages.push(message);
    },
    update_current_api_link(state, value) {
      state.current_api_link = value
    },
    set_config(state, config){
      state.config = config
    },
    set_mobile(state, mobile){
      state.mobile = mobile
    }

  },
  actions: {
    perform_query(state, queryword) {
      let query = this.state.current_api_link + "msg=" + queryword
      console.log(query)
      fetch(query)
        .then(response => response.json())
        .then(data => this.commit("add_messages", data))
        .catch(err => console.log(err))
    },

  },
  modules: {
  },
  getters: {
    get_chatmessages: (state) => state.chatmessages,
    get_config: (state) => state.config,
    get_mobile: (state) => state.mobile
  }
})
