<template>
  <div class="button-list">
    <button
      :id="option_index + message_index * 100"
      :style="cssVars"
      v-on:click="clickButton"
      :class="{ clickable: is_clickable, message_button: true }"
      :query="option.value"
      v-for="(option, option_index) in options"
      :key="option_index + message_index * 10"
    >
      {{ option.answer }}
    </button>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  name: "Buttons",
  props: ["options", "n_cols", "message_index"],
  data() {
    return {
      is_clickable: true,
    };
  },
  methods: {
    ...mapActions(["perform_query"]),
    ...mapMutations(["add_message"]),

    clickButton(event) {
      console.log(event.target.attributes.query.nodeValue);
      if (this.is_clickable) {
        this.perform_query(event.target.attributes.query.nodeValue);
        this.is_clickable = false;
        event.target.style.background = "var(--button-selected)";
        event.target.style.color = "var(--font-color-button-selected)";
      }
    },
  },
  computed: {
    cssVars() {
      if (this.$store.state.mobile) {
        return { "--width": "90%"}
      }
      return {
        "--width": 90 / this.n_cols + "%",
      };
    },
  },
};
</script>

<style scoped>
.button-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

button {
  margin: 2px;
  padding: 5px;
  width: var(--width);
  min-width: var(--width);
  border-radius: 7px;
  border-width: 1px !important;
  border: solid;
  border-color: lightgrey;
  background: var(--button-not-clickable);
  font-size: var(--text-size);
  color: var(--font-color);
  transition: background 0.5s;
  transition: color 0.1s;

}
button.clickable {
  margin: 2px;
  padding: 5px;
  border-radius: 7px;
  border: 1px solid lightgray !important;
  background: white;
  cursor: pointer;
}
@media (pointer: fine) {
  button.clickable:hover {
    background: var(--button-hover);
    color: var(--font-color-button-selected);
  }
}

</style>
