<template>
  <div class="message-container">
    <div :class="{ message: true, in: message.in, out: !message.in }">
      <div v-if="'image' in message">
        <img :src="message.image" alt="" srcset="" />
      </div>
      <p class="message_text" v-html="reformatMessage(message.string)"></p>

      <div v-if="'options' in message">
        <br>
        <ChatButtonList
          :key="options"
          :options="message.options"
          :n_cols="message.n_cols"
          :message_index="message_index"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ChatButtonList from "@/components/Chat/ChatButtonList";
export default {
  components: { ChatButtonList },
  props: ["message", "message_index"],
  methods: {
    reformatMessage(message) {
      var link = message.match(/(https?:\/\/[^\\]+)/g);
      if (link != null && link[0] != null) {
        message = message.replace(
          link[0],
          "<a href=" + link[0] + ' target="_blank">' + link[0] + "</a>"
        );
      }
      return message;
    },
  },
};
</script>

<style scoped>
.message {
  padding: 0.4em 1em 0.4em 1em;
  background-color: var(--lighter-bg-color);
  border-radius: 10px;
  max-width: 75%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.19);
}
.out {
  margin-left: auto;
  background: var(--bubble-color);
  color: var(--message-out-font-color);

  animation: slideInRight;
  animation-duration: 0.2s;
}

.in {
  animation: slideInLeft;
  animation-duration: 0.2s;
  color: var(--message-in-font-color);

}

img {
  max-width: 100%;
  height: auto;
}

.message_text {
  text-align: left;
}
.message-container {
  margin: 1em 2em 0.8em 1em;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 700px) {
  .message {
    max-width: 85%;
  }
}


</style>
